import _toConsumableArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export var groupByParentId = function groupByParentId(makes, models, extins, filterField, optionsList) {
  var makesValues = (makes === null || makes === void 0 ? void 0 : makes.values) || [];
  var modelsValues = (models === null || models === void 0 ? void 0 : models.values) || [];
  var extinsValues = (extins === null || extins === void 0 ? void 0 : extins.values) || [];
  var isModel = filterField === "model_id";
  var isExtins = filterField === "trim_id";
  var parentIds = [];
  if (isModel) {
    modelsValues.forEach(function (model) {
      var modelParent = makesValues.find(function (parent) {
        return (parent === null || parent === void 0 ? void 0 : parent.id) === model.parent_ids[0];
      });
      if (!modelParent) return;
      if (!parentIds.includes(modelParent === null || modelParent === void 0 ? void 0 : modelParent.id)) parentIds.push(modelParent.id);
    });
    return parentIds.map(function (id) {
      var parent = makesValues.find(function (parent) {
        return parent.id === id;
      });
      var children = modelsValues.filter(function (child) {
        return child.parent_ids[0] === id;
      });
      return _objectSpread(_objectSpread({}, parent), {}, {
        values: children
      });
    });
  }
  if (isExtins) {
    extinsValues.forEach(function (ext) {
      var extParent = modelsValues.find(function (parent) {
        return parent.id === ext.parent_ids[0];
      });
      if (!extParent) return;
      if (!parentIds.includes(extParent.id)) parentIds.push(extParent.id);
    });
    return parentIds.map(function (id) {
      var model = modelsValues.find(function (parent) {
        return parent.id === id;
      });
      var make = makesValues.find(function (parent) {
        return parent.id === model.parent_ids[0];
      });
      var children = extinsValues.filter(function (child) {
        return child.parent_ids[0] === id;
      });
      if (!make || !model) {
        console.log("make", {
          make: make,
          model: model,
          extinsValues: extinsValues,
          modelsValues: modelsValues,
          makesValues: makesValues
        });
      }
      return _objectSpread(_objectSpread({}, model), {}, {
        name: "".concat(make.name, " ").concat(model.name),
        values: children
      });
    });
  }
  if (optionsList) {
    var values = optionsList.values;
    var parents = values.map(function (value) {
      return value.parent;
    });
    var uniqueItems = new Set();
    var resultArray = parents.filter(function (item) {
      var itemString = JSON.stringify(item);
      var isUnique = !uniqueItems.has(itemString);
      if (isUnique) {
        uniqueItems.add(itemString);
      }
      return isUnique;
    });
    return resultArray.map(function (parent) {
      var newValues = values.filter(function (value) {
        return value.parent.parent_id === parent.parent_id;
      });
      return _objectSpread(_objectSpread({}, parent), {}, {
        name: parent.name,
        values: newValues
      });
    });
  }
};
export var mapFacets = function mapFacets(facets) {
  var finalFacets = [];
  var makes = facets.find(function (facet) {
    return (facet === null || facet === void 0 ? void 0 : facet.filterField) === "make_id";
  });
  var models = facets.find(function (facet) {
    return (facet === null || facet === void 0 ? void 0 : facet.filterField) === "model_id";
  });
  var extensions = facets.find(function (facet) {
    return (facet === null || facet === void 0 ? void 0 : facet.filterField) === "trim_id";
  });
  facets.forEach(function (facet) {
    if ((facet === null || facet === void 0 ? void 0 : facet.filterField) === "model_id") {
      finalFacets.push(_objectSpread(_objectSpread({}, facet), {}, {
        values: groupByParentId(makes, models, extensions, facet === null || facet === void 0 ? void 0 : facet.filterField)
      }));
    } else if ((facet === null || facet === void 0 ? void 0 : facet.filterField) === "trim_id") {
      finalFacets.push(_objectSpread(_objectSpread({}, facet), {}, {
        values: groupByParentId(makes, models, extensions, facet === null || facet === void 0 ? void 0 : facet.filterField)
      }));
    } else if ((facet === null || facet === void 0 ? void 0 : facet.filterField) === "options_list") {
      finalFacets.push(_objectSpread(_objectSpread({}, facet), {}, {
        values: groupByParentId(makes, models, extensions, facet === null || facet === void 0 ? void 0 : facet.filterField, facet)
      }));
    } else {
      finalFacets.push(facet);
    }
  });
  return finalFacets;
};
export function mapSelected(selected) {
  var finalArray = [];
  var makes = selected.filter(function (child) {
    return (child === null || child === void 0 ? void 0 : child.filterField) === "make_id";
  });
  var models = selected.filter(function (child) {
    return (child === null || child === void 0 ? void 0 : child.filterField) === "model_id";
  });
  var extins = selected.filter(function (child) {
    return (child === null || child === void 0 ? void 0 : child.filterField) === "trim_id";
  });
  var otherFacets = selected.filter(function (child) {
    return (child === null || child === void 0 ? void 0 : child.filterField) !== "make_id" && (child === null || child === void 0 ? void 0 : child.filterField) !== "model_id" && (child === null || child === void 0 ? void 0 : child.filterField) !== "trim_id";
  });
  extins.forEach(function (ext) {
    var extMakeId = ext.parent_ids[1]; // ! will be parent_idss
    var extModelId = ext.parent_ids[0];
    var model = models.find(function (mod) {
      return mod.id === extModelId;
    });
    var make = makes.find(function (mak) {
      return mak.id === extMakeId;
    });
    if (!finalArray.some(function (item) {
      return item.id === (make === null || make === void 0 ? void 0 : make.id) && item.filterField === (make === null || make === void 0 ? void 0 : make.filterField);
    })) finalArray.push(make);
    if (!finalArray.some(function (item) {
      return item.id === (model === null || model === void 0 ? void 0 : model.id) && (item === null || item === void 0 ? void 0 : item.filterField) === (model === null || model === void 0 ? void 0 : model.filterField);
    })) finalArray.push(model);
    finalArray.push(ext);
  });
  models.forEach(function (model) {
    var modelMakeId = model.parent_ids[0];
    var make = makes.find(function (mak) {
      return mak.id === modelMakeId;
    });
    if (!finalArray.some(function (item) {
      return item.id === make.id && (item === null || item === void 0 ? void 0 : item.filterField) === (make === null || make === void 0 ? void 0 : make.filterField);
    })) finalArray.push(make);
    if (!finalArray.some(function (item) {
      return item.id === model.id && (item === null || item === void 0 ? void 0 : item.filterField) === (model === null || model === void 0 ? void 0 : model.filterField);
    })) finalArray.push(model);
  });
  makes.forEach(function (make) {
    if (!finalArray.some(function (item) {
      return item && make && item.id === make.id && item.filterField === make.filterField;
    })) finalArray.push(make);
  });
  return [].concat(finalArray, _toConsumableArray(otherFacets));
}
export var isRangeFilter = function isRangeFilter(filterName) {
  var rangeFilters = ["year", "odometer", "sellingprice", "monthly_installment"];
  return rangeFilters.includes(filterName);
};
export var prepareDeletedFilters = function prepareDeletedFilters(filter, selectedOptions) {
  var isRange = isRangeFilter(filter === null || filter === void 0 ? void 0 : filter.filterField);
  var IsMake = (filter === null || filter === void 0 ? void 0 : filter.filterField) === "make_id";
  var IsModel = (filter === null || filter === void 0 ? void 0 : filter.filterField) === "model_id";
  if (isRange) {
    return selectedOptions.filter(function (option) {
      return (option === null || option === void 0 ? void 0 : option.filterField) !== (filter === null || filter === void 0 ? void 0 : filter.filterField);
    });
  }
  if (IsMake) {
    return selectedOptions.filter(function (option) {
      var isDeletedMake = (option === null || option === void 0 ? void 0 : option.filterField) === "make_id" && option.id === filter.id;
      var isMakeModel = (option === null || option === void 0 ? void 0 : option.filterField) === "model_id" && filter.id === option.parent_ids[0];
      var isMakeTrim = (option === null || option === void 0 ? void 0 : option.filterField) === "trim_id" && filter.id === option.parent_ids[1];
      return !isDeletedMake && !isMakeModel && !isMakeTrim;
    });
  }
  if (IsModel) {
    return selectedOptions.filter(function (option) {
      var isDeletedModel = (option === null || option === void 0 ? void 0 : option.filterField) === "model_id" && option.id === filter.id;
      var isModelTrim = (option === null || option === void 0 ? void 0 : option.filterField) === "trim_id" && filter.id === option.parent_ids[0];
      return !isDeletedModel && !isModelTrim;
    });
  }
  return selectedOptions.filter(function (option) {
    var isDeletedFilter = (option === null || option === void 0 ? void 0 : option.filterField) === (filter === null || filter === void 0 ? void 0 : filter.filterField) && option.id === filter.id;
    return !isDeletedFilter;
  });
};
export function validateFilters(arr) {
  // Create a set of unique parent IDs in the array
  var makesIds = new Set(arr.filter(function (item) {
    return (item === null || item === void 0 ? void 0 : item.filterField) === "make_id";
  }).map(function (item) {
    return item.id;
  }));
  var modelsIds = new Set(arr.filter(function (item) {
    return (item === null || item === void 0 ? void 0 : item.filterField) === "model_id";
  }).map(function (item) {
    return item.id;
  }));

  // Filter the array to include only objects with valid parent IDs
  var filteredArray = arr.filter(function (item) {
    if ((item === null || item === void 0 ? void 0 : item.filterField) === "model_id") {
      return makesIds.has(item.parent_ids[0]);
    }
    if ((item === null || item === void 0 ? void 0 : item.filterField) === "trim_id") {
      return makesIds.has(item.parent_ids[1]);
    }
    return true;
  });
  return filteredArray;
}