import { ucWords } from "@/utils/stringHelpers";
import brands_tree_ar from "@data/cars-prices-data/brands_tree_ar.json";
import brands_tree_en from "@data/cars-prices-data/brands_tree_en.json";
export var CARS_PRICES_BASE_PATH = '/prices';
var MIN_YEAR = 2022;
export var carPrices__translateText = function carPrices__translateText(string, selectedMake, selectedModel, year, lang) {
  if (!string) return '';
  var makeName = (selectedMake === null || selectedMake === void 0 ? void 0 : selectedMake.name) || '';
  var modelName = (selectedModel === null || selectedModel === void 0 ? void 0 : selectedModel.name) || '';
  return string.replaceAll('{make}', ucWords(makeName)).replaceAll('{model}', ucWords(modelName)).replaceAll('{year}', year + '');
};
export var cars_prices__urlHelper = function cars_prices__urlHelper(url, lang) {
  var make = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var model = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var year = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  if (!url) return '';
  var langUrl = lang === 'en' ? '/en' : '';
  var makeSlug = make !== null && make !== void 0 && make.seo_url ? "/".concat(make === null || make === void 0 ? void 0 : make.seo_url) : '';
  var modelSlug = model !== null && model !== void 0 && model.seo_name ? "/".concat(model === null || model === void 0 ? void 0 : model.seo_name) : '';
  var yearSlug = year ? "/".concat(year) : '';
  url = url.replace('car-prices', CARS_PRICES_BASE_PATH).replace('en/cars-prices', CARS_PRICES_BASE_PATH).replace('prices', CARS_PRICES_BASE_PATH).replace('en/prices', CARS_PRICES_BASE_PATH);
  return '/' + (langUrl + url.replaceAll('{make}', makeSlug).replaceAll('{model}', modelSlug).replaceAll('{year}', yearSlug)).split('/').filter(Boolean).join('/');
};
export var xxxx_getMakesModels = function xxxx_getMakesModels(lang) {
  var selectedMake = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var selectedModel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var brands_tree = {
    ar: brands_tree_ar,
    en: brands_tree_en
  };
  var makes = brands_tree[lang].makes;
  var models = brands_tree[lang].models.filter(function (m) {
    return m.make_id === (selectedMake === null || selectedMake === void 0 ? void 0 : selectedMake.id);
  });
  //const years = [2021, 2022, 2023, 2024];
  var currentYear = new Date().getFullYear();
  //create array of years starting from MIN_YEAR to current year + 1
  var years = Array.from({
    length: currentYear - MIN_YEAR + 2
  }, function (_, i) {
    return MIN_YEAR + i;
  });
  return {
    makes: makes || [],
    models: models || [],
    years: years
  };
};
export var createCarPricesUrl = function createCarPricesUrl(make, model, year) {
  var _make$name, _model$name;
  var urlParts = [CARS_PRICES_BASE_PATH, (make === null || make === void 0 ? void 0 : make.seo_url) || (make === null || make === void 0 ? void 0 : (_make$name = make.name) === null || _make$name === void 0 ? void 0 : _make$name.slugify()), (model === null || model === void 0 ? void 0 : model.seo_name) || (model === null || model === void 0 ? void 0 : (_model$name = model.name) === null || _model$name === void 0 ? void 0 : _model$name.slugify()), year];
  return urlParts.filter(Boolean).join('/');
};