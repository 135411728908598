import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { parseJsonSafely } from "@/utils/GlobalJavascriptFunction";
import { useEffect } from "react";
var LOCAL_STORAGE_LAST_POSITION_KEY = "last_position";
var LOCAL_STORAGE_SEARCH_RES_KEY = "last_search_results";
var LOCAL_STORAGE_PATH_TYPE_KEY = "last_path_type";
var PATH_TYPE_MAP = {
  'filters': ['/filters'],
  'autos': ['/autos'],
  'years': ['/years'],
  'details': ['/cardetail', '/car-compare', '/car/']
};
var isAllowedPagePath = function isAllowedPagePath(allowed_page_path) {
  if (__IS_SSR__) return false;
  return !!window.prevPath && window.prevPath.includes(allowed_page_path);
};
var cleanLastPosition = function cleanLastPosition() {
  if (__IS_SSR__) return;
  sessionStorage.removeItem(LOCAL_STORAGE_LAST_POSITION_KEY);
};
var cleanLastStorageData = function cleanLastStorageData() {
  if (__IS_SSR__) return;
  sessionStorage.removeItem(LOCAL_STORAGE_SEARCH_RES_KEY);
};
var preserveData = function preserveData(searchResultsData) {
  if (__IS_SSR__) return false;
  sessionStorage.setItem(LOCAL_STORAGE_SEARCH_RES_KEY, JSON.stringify(searchResultsData));
};
var preserveScrollPosition = function preserveScrollPosition() {
  if (__IS_SSR__) return false;
  sessionStorage.setItem(LOCAL_STORAGE_LAST_POSITION_KEY, window.scrollY.toString());
};
var getCurrentPathType = function getCurrentPathType(pathname) {
  for (var _i = 0, _Object$entries = Object.entries(PATH_TYPE_MAP); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      type = _Object$entries$_i[0],
      patterns = _Object$entries$_i[1];
    if (patterns.some(function (pattern) {
      return pathname.includes(pattern);
    })) {
      return type;
    }
  }
  return null;
};
var usePreserveData = function usePreserveData() {
  if (!__IS_SSR__) {
    // Get current pathname and path type
    var currentPath = window.location.pathname;
    var currentPathType = getCurrentPathType(currentPath);

    // Get previous path type from localStorage
    var previousPathType = sessionStorage.getItem(LOCAL_STORAGE_PATH_TYPE_KEY);

    // If navigating between different supported path types, clear data
    if (previousPathType && currentPathType && previousPathType !== currentPathType) {
      sessionStorage.removeItem(LOCAL_STORAGE_LAST_POSITION_KEY);
      sessionStorage.removeItem(LOCAL_STORAGE_SEARCH_RES_KEY);
    }

    // Update the stored path type
    if (currentPathType) {
      sessionStorage.setItem(LOCAL_STORAGE_PATH_TYPE_KEY, currentPathType);
    }
  }
  var lastScrollPosition = 0;
  var lastSearchResultsData = null;
  lastScrollPosition = __IS_SSR__ ? 0 : Number(sessionStorage.getItem(LOCAL_STORAGE_LAST_POSITION_KEY));
  lastSearchResultsData = __IS_SSR__ ? null : parseJsonSafely(sessionStorage.getItem(LOCAL_STORAGE_SEARCH_RES_KEY));
  cleanLastPosition(); // Clean the scroll position after using it

  return {
    preserveData: preserveData,
    preserveScrollPosition: preserveScrollPosition,
    lastScrollPosition: Number(lastScrollPosition),
    lastSearchResultsData: lastSearchResultsData
  };
};
export var useMaintainSearchResultsDataStorage = function useMaintainSearchResultsDataStorage() {
  var _window;
  if (__IS_SSR__) {
    return;
  }
  var pathname = (_window = window) === null || _window === void 0 ? void 0 : _window.location.pathname;
  useEffect(function () {
    // Check if current path is a supported type
    var currentPathType = getCurrentPathType(pathname);
    if (!currentPathType) {
      cleanLastPosition();
      cleanLastStorageData();
      // Also clear the path type
      sessionStorage.removeItem(LOCAL_STORAGE_PATH_TYPE_KEY);
    }
  }, [pathname]);
};
export default usePreserveData;