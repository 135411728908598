import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import checkoutReducer from "./checkoutReducer";
import surveyReducer from "./surveyReducer";
// Removed serviceCentersReducer import as it's now handled by useApi hook

// FIXME: Remove this bad Reducer
var reducers = combineReducers({
  common: commonReducer,
  checkout: checkoutReducer,
  survey: surveyReducer
  // Removed serviceCenters reducer as it's now handled by useApi hook
});
export default reducers;