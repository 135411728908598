import {
  SECONDARY_LOADING,
  CONTACT_US_SUBMIT,
  CONTACT_US_ERRORS,
} from "../types";
const initialState = {
  secondaryLoading: false,
  postLoading: false
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SECONDARY_LOADING:
      return {
        ...state,
        secondaryLoading: payload,
      };

    case CONTACT_US_SUBMIT:
      return { ...state, ContactUsSubmit: payload.data };
    case CONTACT_US_ERRORS:
      return { ...state, errors: payload.data };
    default:
      return state;
  }
};
export default commonReducer;
