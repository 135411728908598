export function checkCarConditionHelper(post) {
  var _post$details;
  if (post !== null && post !== void 0 && (_post$details = post.details) !== null && _post$details !== void 0 && _post$details.details_card) {
    var _post$details$details;
    if ((_post$details$details = post.details.details_card.is_new) !== null && _post$details$details !== void 0 && _post$details$details.id) {
      return "new";
    } else {
      return "used";
    }
  }
  return "unknown";
}