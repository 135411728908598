import {
  GET_ORDER_DETAILS,
  CREATE_ORDER,
  REFERRAL_ORDER,
  UPDATEFIELDS,
  CREATE_ORDER_ERROR,
  RESET_CHECKOUT_FIELDS,
} from "../types";

const initialState = {
  hasSadad: null,
  sadad: { is_enabled: false, invoice_type: "", invoices: [] },
  sadadError: "",
  order: null,
  orderCreated: null,
  referralData: null,
  create_order_errors: {},
  updatedValues: {
    whatsapp_optin: 1,
  },
  financeRemainingOrder: {
    order_id: 0,
    customer_name: "",
    post_title: "",
    post_image: "",
    total_amount: "",
    remaining_amount: "",
    paid_amount: "",
    service_amount: "",
    vas_amount: "",
    paymentOptions: {},
    date: "",
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER_DETAILS:
      return { ...state, order: payload };
    case CREATE_ORDER:
      return { ...state, orderCreated: payload };
    case REFERRAL_ORDER:
      return { ...state, referralData: payload };
    case UPDATEFIELDS:
      return {
        ...state,
        updatedValues: {
          ...state.updatedValues,
          [payload.key]: payload.value,
        },
      };

    case RESET_CHECKOUT_FIELDS:
      return {
        ...state,
        updatedValues: initialState.updatedValues,
        referralData: null,
      };
    case CREATE_ORDER_ERROR:
      return { ...state, create_order_errors: payload };
    default:
      return state;
  }
};
