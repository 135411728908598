export const SECONDARY_LOADING = "SECONDARY_LOADING";
export const POST_OFFLINE_ORDER = "POST_OFFLINE_ORDER";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const CREATE_ORDER = "CREATE_ORDER";
export const REFERRAL_ORDER = "REFERRAL_ORDER";
export const UPDATEFIELDS = "UPDATEFIELDS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const CONTACT_US_SUBMIT = "CONTACT_US_SUBMIT";
export const CONTACT_US_ERRORS = "CONTACT_US_ERRORS";
export const RESET_CHECKOUT_FIELDS = "RESET_CHECKOUT_FIELDS";

export const SURVEY_DATA = "SURVEY_DATA";
export const SURVEY_ORDER_DATA = "SURVEY_ORDER_DATA";
export const SET_SURVEY_CODE = "SET_SURVEY_CODE";

export const GET_NEW_SERVEY_DATA = "GET_NEW_SERVEY_DATA";
export const SET_SUBMIT_LOADING = "SET_SUBMIT_LOADING";

